import React from 'react';
import {
    MenuItem,
    TextField,
    Grid,
    Chip,
  } from "@mui/material";

function GridFAL({handler}, {edificio}) {
  return (
    <div>
        <Grid item xs={3}>
            <TextField
              label="Prédios FAL"
              variant="outlined"
              value={edificio}
              style={{ minWidth: 130, maxWidth: 150 }}
              select
              onChange={handler}
              SelectProps={{
                multiple: false,
                renderValue: (selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <Chip key={selected} label={selected} style={{ margin: 2 }} />
                  </div>
                ),
              }}
            >
                <MenuItem  value={"Sede da Fazenda Água Limpa"}>Sede da Fazenda Água Limpa</MenuItem>
                <MenuItem  value={"Prédio do Departamento de Engenharia Florestal"}>Prédio do Departamento de Engenharia Florestal</MenuItem>
                <MenuItem  value={"Observatório Astronômico"}>Observatório Astronômico</MenuItem>
                <MenuItem  value={"Moradia de funcionários"}>Moradia de funcionários</MenuItem>
                <MenuItem  value={"Laboratório de Prim atologia - Viveiro"}>Laboratório de Prim atologia - Viveiro</MenuItem>
                <MenuItem  value={"Laboratório de Primatologia - Quarentena"}>Laboratório de Primatologia - Quarentena</MenuItem>
                <MenuItem  value={"Laboratório de Primatologia - Callithrix"}>Laboratório de Primatologia - Callithrix</MenuItem>
                <MenuItem  value={"Laboratório de Primatologia"}>Laboratório de Primatologia</MenuItem>
                <MenuItem  value={"Laboratório de Nutrição Animal"}>Laboratório de Nutrição Animal</MenuItem>
                <MenuItem  value={"Laboratório de Ensaios Metabólicos"}>Laboratório de Ensaios Metabólicos</MenuItem>
                <MenuItem  value={"Laboratorio de Ecologia de Campo"}>Laboratorio de Ecologia de Campo</MenuItem>
                <MenuItem  value={"Laboratório de Campo do Instituto de Biologia"}>Laboratório de Campo do Instituto de Biologia</MenuItem>
                <MenuItem  value={"Laboratório de Adubos e Adubações"}>Laboratório de Adubos e Adubações</MenuItem>
                <MenuItem  value={"Guaritas"}>Guaritas</MenuItem>
                <MenuItem  value={"Galpão Marcenaria"}>Galpão Marcenaria</MenuItem>
                <MenuItem  value={"Galpão de M áquinas Agrícolas"}>Galpão de M áquinas Agrícolas</MenuItem>
                <MenuItem  value={"Galpão de Manejo de Ovinos"}>Galpão de Manejo de Ovinos</MenuItem>
                <MenuItem  value={"Fábrica de Laticínios"}>Fábrica de Laticínios</MenuItem>
                <MenuItem  value={"Estufa da Engenharia Florestal"}>Estufa da Engenharia Florestal</MenuItem>
                <MenuItem  value={"Depósito Geral"}>Depósito Geral</MenuItem>
                <MenuItem  value={"Depósito FAL"}>Depósito FAL</MenuItem>
                <MenuItem  value={"Depósito de Materiais I"}>Depósito de Materiais I</MenuItem>
                <MenuItem value={"Depósito de Materiais II"}>Depósito de Materiais II</MenuItem>
                <MenuItem value={"Depósito de Agrotóxicos"}>Depósito de Agrotóxicos</MenuItem>
                <MenuItem value={"Depósito da Hortaliça"}>Depósito da Hortaliça</MenuItem>
                <MenuItem value={"Casa Vegetação Hortaliças"}>Casa Vegetação Hortaliças</MenuItem>
                <MenuItem value={"Casa de Vegetação"}>Casa de Vegetação</MenuItem>
                <MenuItem value={"Carvoaria"}>Carvoaria</MenuItem>
                <MenuItem value={"Área Experimental de Agroclimatologia"}>Área Experimental de Agroclimatologia</MenuItem>
                <MenuItem value={"Alojamento de Colonos"}>Alojamento de Colonos</MenuItem>
                <MenuItem value={"Almoxarifado"}>Almoxarifado</MenuItem>
                <MenuItem value={"Área aberta"}>Área aberta</MenuItem>
                
            </TextField>
          </Grid>

    </div>
  )
}

export default GridFAL